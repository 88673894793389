import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import FormRegisterCompany from "../components/forms/formRegisterCompany"
import FormRegisterCustomer from "../components/forms/formRegisterCustomer"
import FormRegisterDealer from "../components/forms/formRegisterDealer"

const Customer = () => {
  const [modal, setModal] = useState(false)

  const [modalRegisterCustomer, setModalRegisterCustomer] = useState(false)
  const [
    modalRegisterTransportCompany,
    setModalRegisterTransportCompany,
  ] = useState(false)
  const [modalRegisterCarDealer, setModalRegisterCarDealer] = useState(false)

  const resetModal = () => {
    setModal(false)
    setModalRegisterCustomer(false)
    setModalRegisterTransportCompany(false)
    setModalRegisterCarDealer(false)
  }

  return (
    <Layout>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <SEO title="Customer" />

      <section className="subpage-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>
                Car transport has never been <span>easier</span>
              </h2>
              <button
                onClick={e => setModal(true)}
                className="btn-custom btn-custom--green"
              >
                Register now
              </button>
            </div>
            <div className="col-lg-6 text-right">
              <img
                className="img-fluid img-main"
                src={require("../assets/images/global-phone.png")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="subpage-icons">
        <div className="container-fluid">
          <h2>
            Find genuine transport <br /> company with us
          </h2>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/customer-ico-1.svg")}
                  alt=""
                />
              </div>
              <h4>
                Save <br />
                time and money
              </h4>
              <p>
                Individual profile and useful apps, that you can personalise to
                your taste, will minimalize the time you need to spend to get
                the work done!
              </p>
            </div>
            <div className="col-md-4">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/customer-ico-2.svg")}
                  alt=""
                />
              </div>
              <h4>
                Receive <br />
                unique customer profile
              </h4>
              <p>
                Be in control of how you want your profile to look! We provide
                the tools and you fully customise your own website. Stand out
                from the crowd!
              </p>
            </div>
            <div className="col-md-4">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/customer-ico-3.svg")}
                  alt=""
                />
              </div>
              <h4>
                Chat with <br />
                transport companies
              </h4>
              <p>
                Easy way to communicate with drivers and dealerships. Simple and
                free built-in app avaiable in your own personal profile to
                simplify communication regarding your service.
              </p>
            </div>
            <div className="col-md-4">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/customer-ico-4.svg")}
                  alt=""
                />
              </div>
              <h4>
                Rewards <br />
                scheme
              </h4>
              <p>
                Collect point and redeem them! We will promise to reward every
                time you use our website.
              </p>
            </div>
            <div className="col-md-4">
              <div className="subpage-icons__ico">
                <img
                  src={require("../assets/images/customer-ico-5.svg")}
                  alt=""
                />
              </div>
              <h4>
                Easy tools <br />
                to track the driver
              </h4>
              <p>
                Be up-to-speed with the location of your driver. You are able to
                see the location on the map and estimated time of the arrival.
              </p>
            </div>
          </div>
        </div>
        <div className="subpage-icons__action">
          <button
            onClick={e => setModal(true)}
            className="btn-custom btn-custom--green"
          >
            Register
          </button>
        </div>
      </section>

      {modal && (
        <>
          <div className="custom-modal">
            {modalRegisterCustomer === true ? (
              <FormRegisterCustomer />
            ) : modalRegisterTransportCompany === true ? (
              <FormRegisterCompany />
            ) : modalRegisterCarDealer === true ? (
              <FormRegisterDealer />
            ) : (
              <>
                <h3>Continue as</h3>
                <div className="row justify-content-center">
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterCustomer(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-customer.svg")}
                          alt=""
                        />
                      </div>
                      <p>Customer</p>
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterTransportCompany(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-transport-company.svg")}
                          alt=""
                        />
                      </div>
                      <p>
                        Transport <br /> Company
                      </p>
                    </button>
                  </div>
                  <div className="col-sm-4">
                    <button
                      onClick={e => setModalRegisterCarDealer(true)}
                      className="tile"
                    >
                      <div className="tile__thumb">
                        <img
                          src={require("../assets/images/ico-car-dealer.svg")}
                          alt=""
                        />
                      </div>
                      <p>
                        Car <br /> Dealer
                      </p>
                    </button>
                  </div>
                </div>
              </>
            )}
            <button
              className="custom-modal__close"
              onClick={resetModal}
            ></button>
          </div>
          <button
            className="custom-modal__overlay"
            onClick={resetModal}
          ></button>
        </>
      )}
    </Layout>
  )
}

export default Customer
